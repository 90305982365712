*{
    padding: 0rem;
    margin: 0rem; 
    /* font-size: 32px; */
}

.home .txt{
    font-family: fancyFont !important;
}

.home_outer{
    padding: 0rem;
    margin: 0rem;

    color:white;

    height:100vh;
    width:100vw;

    --black-box-padding: 4vh;
}

.home_outer_content{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.home .countdown{
    text-align:center;
    font-size: 2rem;
}

.home_outer  .centerContent {
    font-size: 2rem;
}

.home_outer  .centerContent .the_one_img {
    justify-self: center;
    align-self: center;
    /* height: calc(60 * var(--cvh)); */
}

.home .the_one_img > img{
    max-height: 100%;
    /* max-height: min(100%, 30rem); */
    max-width: 100%;

    justify-self: center;
    align-self: center;
}

/* weird mobile stuff */
@media (min-resolution: 2.1dppx) {
    
    @media (orientation: landscape) {
        .leaves_img{
            max-width:30%;
            max-height: 100%;
        }
    }
      
    @media (orientation: portrait) {
        /* .leaves_img{
            max-width:30%;
            max-height: 100%;
        } */
    }
}