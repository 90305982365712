.Program{
    position: fixed;
    font-size: 2rem;

    --top: calc( 40vw * (1611/2363) * (864/1611) + 5vh );
    top: var(--top);
    height: calc(100vh - var(--top) - var(--black-box-padding));

    flex-wrap: wrap;
    justify-content: center;

    width: calc(100vw - 2*var(--black-box-padding) );

    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    /* overflow-y: scroll; */

    display:flex;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.ProgramWrapper{
    /* padding: 1rem; */
    /* width: 100%; */
    display:flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    /* justify-content: space-evenly; */
    /* margin-bottom: 4rem; */
    max-width: 1400px;
    max-width: 60%;
    align-items: center;
}

.ProgramWrapper > *{
    padding: .5rem;
    padding-bottom: 1.5rem;
}

.Program .event{
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Program .page_title{
    font-size: 3rem;
}

.Program .icon{
    height: 5rem;
}

.Program .event .extra_text{
    font-size: 1rem;
    font-style: italic;
    /* padding: .5rem; */
}
