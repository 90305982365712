
@font-face {
    font-family: fancyFont;
    src: url(/LaBelle.woff2);
}

@font-face {
    font-family: plainFont;
    src: url(/Timeless.tff);
}

.fancyFont{
  font-family: fancyFont !important;
}

* {
  margin:0px;
  padding:0px;
  font-family: plainFont;
}

.image_holder{
  display: grid;
  background-color: black;
  height: 100vh;
  width: 100vw;
}

.image_holder > img{
  align-self: auto; 
  place-self: center; 
  max-height: 100%;
  max-width: 100%;
}


/* topbar */
.is_mobile #topbar{
  --font-size: 3rem;
  
  transition: height .5s;

  position: fixed;
  z-index: 100;
  top: 0;
  overflow: hidden;

  width: 100vw;
  text-align: center;

  font-size:var(--font-size);
}
.is_mobile.closed #topbar{
  height: 0rem;
  padding:0rem;
  overflow: hidden !important; 
}
.is_mobile.open #topbar{
  /* do I need this? */
  background-color: black;
  height: calc(5 * var(--font-size));
}
.is_mobile .topbar_wrapper{
  padding: 1rem;
  background-color: black;
  font-size: 2rem;
}
.is_desktop #topbar{
  position: fixed;
  /* top: 24vh; */
  top: calc( 40vw * (1611/2363) * (864/1611) ); /* account for height of leaves */
  
  /* overflow-x: scroll; */

  margin: 0rem;
  padding: 0rem;

  width: calc(100vw - 2*var(--black-box-padding));

  display: flex;
  justify-content: center;
}
.is_desktop .topbar_wrapper {
  display:flex;
  justify-content: center;

  --font-size: 1.5rem;
  font-size: var(--font-size);
  height: calc(var(--font-size));
  overflow: visible;
  /* display:inline-block; */

}
.is_desktop .topbar_wrapper > * {
  margin: 0rem .5rem 0rem .5rem;
  display:flex;
  flex-wrap: nowrap;
  height: calc(var(--font-size));
  overflow: visible;
  white-space: nowrap;
}
.is_desktop .topbar_wrapper > *:hover{
  cursor: pointer;
}


/* overflow icon */
.is_desktop .overflow{
  height:0rem;
  display: none;
}

.is_mobile .overflow{
  height:3rem;
  z-index: 100;
  position: fixed;
}

.overflow > .overflow_icon{
  height:100%;
}

#leaves1{
  right:0px;
  top:0px;
}
#leaves2{
  transform: scaleX(-1);
  left:0px;
  top:0px;
}

.leaves_src{
  max-height:100%;
  max-width:100%;
}

.leaves_img{
    width:40vw;
    position: fixed;
    max-height: 100%;
    max-width:100%;
}
.blackBox.top{
    top: var(--black-box-padding);
    /* top: 0px; */
}
.whiteBox{
    position: fixed;
    background-color: white;
    bottom:0rem;
    width:100vw;
    height: var(--black-box-padding);
    z-index:100;
}

.blackBox{
    position: fixed;
    z-index: -2;
    background-color: black;

    width: calc(100vw - 2*var(--black-box-padding));
    height: calc(100vh - var(--black-box-padding));

    left: var(--black-box-padding);
}

.img_txt{
    /* font-size: 300%; */
    /* margin: 2%; */
    font-weight: bold;
    text-align: center;
    text-align: center;
    height:auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
}

.centerContentWrapper{

    width:calc(100*var(--cvw, 1vw));
    height:calc(100*var(--cvh, 1vh));
    
    color:white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    overflow: hidden;
    
    position: fixed;
    z-index: 3;
}

/* weird mobile stuff */
@media (min-resolution: 2.1dppx) {
    
    @media (orientation: landscape) {
        .centerContent{
            width: 40vw !important;
        }
    }
      
    @media (orientation: portrait) {
        .centerContent{
            width: 70vw !important;
        }
    }
}

.centerContent{
    position: fixed;

    --top: calc( 40vw * (1611/2363) * (864/1611) + 5vh );
    top: var(--top);
    height: calc(100vh - var(--top) - var(--black-box-padding));

    flex-wrap: wrap;
    justify-content: center;

    width: calc(100vw - 2*var(--black-box-padding) );

    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    max-height: calc(70 * var(--cvh));
    max-width: 100%;

    width: 50vw;

    /* overflow-y: scroll; */
}