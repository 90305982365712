.personCard{
    /* background-color: pink; */
    font-size: 1.5rem;
    
    /* max-height: min(30vh, 30vw); */
    max-width: min(25vh, 25vw);
}

.personCard img{
    max-height: min(25vh, 25vw);
    max-width: min(25vh, 25vw);
}

.personCard .person_name{
    /* height:3rem; */
    text-shadow: 0px 0px 2px black;
    background-color: black;
    text-align: center;
    font-family: fancyFont;
}

.personCard .person_relation{
    /* height:5rem; */
    text-shadow: 0px 0px 2px black;
    text-align: center;
}
