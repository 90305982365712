.WeddingParty{
    position: fixed;

    --top: calc( 40vw * (1611/2363) * (864/1611) + 5vh );
    top: var(--top);
    height: calc(100vh - var(--top) - var(--black-box-padding));

    flex-wrap: wrap;
    justify-content: center;

    width: calc(100vw - 2*var(--black-box-padding) );

    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    /* overflow-y: scroll; */

    display:flex;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.WeddingPartyWrapper{
    /* padding: 1rem; */
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 4rem;
    max-width: 1400px;
}

.WeddingPartyWrapper > *{
    padding: .5rem;
}
