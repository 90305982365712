.accommodations .txt{
    text-align: center;
    font-size: 2rem;
    font-family: fancyFont !important;
}

.accommodations .txt *{
    font-family: fancyFont !important;
}

.accommodations .txt > a{
    color:#999c8b;
    font-family: fancyFont !important;
}

.accommodations .txt > a:visited{
    color:#999c8b
}